const Subseccion = () => {
  return (
    <>
      <div className="flex justify-center ">
        <img className="  rounded-3xl" src="/imagenes/prueba1.png" alt="" />
      </div>
    </>
  );
};

export default Subseccion;
